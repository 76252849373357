<template>
  <div>
    <header>
      <div class="center-area">
        <div class="header-content">
          <h1 class="header-title">
            <span class="color-p">店教授自助点餐机</span><br />重新定义您的餐厅
          </h1>
          <div class="header-btn-video-play" id="play-video"></div>
          <a>
            <div class="header-btn-contact">立即咨询</div>
          </a>
          <div class="header-sketch">
            刷脸快速登录会员<br />实现会员引流和营销
          </div>
        </div>
      </div>
      <div data-next=".section-1" data-height="0" class="btn-next-page"></div>
    </header>
    <section class="section-1">
      <div class="center-area">
        <h2 class="section-title">软硬件升级 更流畅的点餐体验</h2>
        <div class="section-subtitle">优化已有问题，新增刷脸支付，快捷安全</div>
        <div class="show-area-1">
          <div class="block">
            <div class="top">点单前</div>
            <div class="desc">首屏广告营销<br />多种会员登录方式</div>
          </div>
          <div class="block">
            <div class="top">点单</div>
            <div class="desc">自定义推荐菜单<br />展示余额优惠券</div>
          </div>
          <div class="block">
            <div class="top">支付</div>
            <div class="desc">多种支付方式<br />刷脸支付更快捷</div>
          </div>
          <div class="block">
            <div class="top">完成</div>
            <div class="desc">订单同步后厨<br />自助叫号取餐</div>
          </div>
        </div>
        <div class="show-area-2 card">
          <div class="left"></div>
          <div class="right">
            <div class="title">店教授自助点餐机</div>
            <div class="flex subtitle">
              <div class="subtitle-block">全新定价</div>
              <div class="subtitle-price">
                <span style="font-size: 32px">¥ </span>3999<span
                  style="font-size: 24px; color: #666; font-weight: normal"
                >
                  起</span
                >
              </div>
            </div>
            <div class="subtitle2">更低的价格，更高的品质</div>
            <div class="other-1">
              <div class="other-1-inner">
                刷脸快速登录会员，实现会员引流和营销
              </div>
            </div>
            <div class="other-2">
              <div class="other-2-line">首次上线刷脸登录/注册会员功能</div>
              <div class="other-2-line">进一步简化会员登录环节</div>
              <div class="other-2-line">后台多种营销模式帮助 运营锁客</div>
            </div>
            <div class="other-3">
              <div class="other-3-item">2+16GB内存</div>
              <div class="other-3-item">21.5英寸显示屏</div>
              <div class="other-3-item">1080P分辨率</div>
              <div class="other-3-item">多点触控电容屏</div>
              <div class="other-3-item">搭载刷脸支付功能</div>
              <div class="other-3-item">4核工控主板</div>
            </div>
          </div>
        </div>
      </div>
      <div data-next=".section-2" data-height="40" class="btn-next-page"></div>
    </section>
    <section class="section-2">
      <div class="center-area">
        <div class="section-title">低成本 高体验</div>
        <div class="show-area-3">
          <div class="card">
            <div class="card-image card-image1"></div>
            <div class="part-1">
              <div class="part-1-title">解放收银台，降低开店成本</div>
              <div class="part-1-content">
                大屏自助点餐，减少高峰期排队；<br />自动同步后厨，降低错单漏单。<br />机器代替人工，解决小店招工难，<br />创造百倍终身效益。
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-image card-image2"></div>
            <div class="part-1">
              <div class="part-1-title">沉浸式点餐体验，减少沟通成本</div>
              <div class="part-1-content">
                人机交流更便捷，<br />多种营销互动，<br />自助无压力式点餐，<br />给顾客充分的考虑和选择时间。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-next=".section-3" data-height="0" class="btn-next-page"></div>
    </section>
    <section class="section-3">
      <div class="center-area">
        <div class="section-title">好营销，无处不在</div>
        <div class="show-area-4">
          <div class="card card-image1">
            <div class="card-image"></div>
            <div class="card-content">
              <div class="card-title">智能化推荐菜单<br />帮助提升客单价</div>
              <div class="card-desc">
                设置推荐商品策略，<br />每一次的点餐推荐，<br />让顾客停留更久，下单更多！
              </div>
            </div>
          </div>
          <div class="card card-image2">
            <div class="card-image"></div>
            <div class="card-content">
              <div class="card-title">自定义广告首屏<br />随时更新的广告位</div>
              <div class="card-desc">
                支持海报、视频展示新品和活动，<br />进店即营销，<br />引导顾客点餐、帮助新品打爆。
              </div>
            </div>
          </div>
          <div class="card card-image3">
            <div class="card-image"></div>
            <div class="card-content">
              <div class="card-title">刷脸即会员<br />帮你轻松引流</div>
              <div class="card-desc">
                搭载刷脸注册和登录会员功能，<br />三秒完成顾客到会员的转化，<br />多种营销模式助力餐厅运营。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-next=".section-4 .center-area"
        data-height="30"
        class="btn-next-page"
      ></div>
    </section>
    <section class="section-4">
      <div class="center-area">
        <div class="section-title">轻松支付 后会有期</div>
        <div class="section-subtitle">支持多种支付方式 提升顾客支付体验</div>
        <div class="section-text">
          微信、支付宝、余额支付<br />新增微信刷脸<br />选择更多，也让支付更加有趣
        </div>
        <div class="image"></div>
      </div>
      <div data-next=".section-5" data-height="0" class="btn-next-page"></div>
    </section>
    <section class="section-5">
      <div class="center-area">
        <div class="image"></div>
        <div class="show-area-5">
          <div class="title">改变</div>
          <div class="subtitle">从点餐开始</div>
          <a>
            <div class="header-btn-contact">立即咨询</div>
          </a>
          <div class="desc">CHANGE STARTS HERE</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "../../../assets/css/cloud/common.css";
import "../../../assets/css/common/common.css";
import "../../../assets/css/common/case.css";
export default {};
</script>

<style>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

.center-area {
  margin: 0 auto;
  width: 1190px;
}

.hide {
  display: none;
}

.color-w {
  color: #ffffff;
}

.color-p {
  color: #00a95f;
}

.color-6 {
  color: #666666;
}

.flex {
  display: flex;
}

.flex-jbetween {
  justify-content: space-between;
}

.flex-acenter {
  align-items: center;
}

.card {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 16px #00000020;
  border: 4px solid #fff;
}

.btn-next-page {
  width: 80px;
  height: 30px;
  background-image: url("../../../assets/images/product/selfOrder/icon-1.png");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: calc(50% - 40px);
  bottom: 76px;
  cursor: pointer;
  z-index: 1;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 10;
}

nav.white {
  background-color: #fff;
  box-shadow: 0 3px 10px #00000020;
}

nav .center-area {
  height: 100%;
}

.nav-title {
  flex: auto;
  margin-left: 26px;
  color: #fff;
  font-size: 16px;
}

nav.white .nav-title {
  color: #666;
}

.nav-logo {
  width: 130px;
  height: 42px;
  background-image: url("../../../assets/images/product/selfOrder/logo.png");
  background-size: cover;
}

nav.white .nav-logo {
  background-image: url("../../../assets/images/product/selfOrder/logo1.png");
}

.nav-tel {
  padding-left: 28px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  color: #fff;
  background-image: url("../../../assets/images/product/selfOrder/tel.png");
  background-size: 18px 18px;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-right: 24px;
}

nav.white .nav-tel {
  color: #666;
  background-image: url("../../../assets/images/product/selfOrder/tel1.png");
}

.nav-btn-contact {
  width: 158px;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 6px;
  text-align: center;
  line-height: 48px;
  font-size: 18px;
  color: #fff;
}

.nav-btn-contact:hover {
  background-color: #00000020;
}

nav.white .nav-btn-contact {
  border: 1px solid #ff6727;
  background-color: #ff6727;
}

nav.white .nav-btn-contact:hover {
  border: 1px solid #fc5d19;
  background-color: #fc5d19;
}

.nav-qq {
  padding-left: 25px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  color: #fff;
  background-image: url("../../../assets/images/product/selfOrder/qq.png");
  background-size: 15px 18px;
  background-position: 0 0;
  background-repeat: no-repeat;
  margin-right: 40px;
}

nav.white .nav-qq {
  color: #666;
  background-image: url("../../../assets/images/product/selfOrder/qq1.png");
}

header {
  height: 1000px;
  background-image: url("../../../assets/images/product/selfOrder/bg-1.png");
  background-size: cover;
  background-position: center;
  position: relative;
}

.header-content {
  width: 960px;
  padding-top: 234px;
  margin: 0 auto;
}

.header-title {
  font-size: 60px;
  font-weight: bold;
  color: #fff;
  line-height: 80px;
}

.header-btn-video-play {
  width: 100px;
  height: 100px;
  margin: 44px 71px;
  background-image: url("../../../assets/images/product/selfOrder/icon-5.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.header-btn-contact {
  width: 240px;
  height: 62px;
  background-color: #00a95f;
  font-size: 22px;
  color: #ffffff;
  line-height: 62px;
  text-align: center;
}

.header-btn-contact:hover {
  background-color: #007743;
}

.header-sketch {
  padding-left: 56px;
  background-image: url("../../../assets/images/product/selfOrder/icon-2.png");
  background-size: 44px 45px;
  background-repeat: no-repeat;
  font-size: 18px;
  color: #00a95f;
  line-height: 24px;
  margin-top: 29px;
}

section {
  background-color: #f1f2f5;
  position: relative;
}

.section-1 {
  height: 1509px;
}

.section-1 .center-area {
  width: 1000px;
  padding-top: 63px;
}

.section-title {
  padding-top: 41px;
  background-image: url("../../../assets/images/product/selfOrder/icon-3.png");
  background-size: 34px 22px;
  background-position: center 0;
  background-repeat: no-repeat;
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  color: #00a95f;
  line-height: 1;
}

.section-subtitle {
  text-align: center;
  margin-top: 35px;
  font-size: 18px;
  color: #666666;
  line-height: 1;
}

.show-area-1 {
  display: flex;
  justify-content: center;
}

.show-area-1 .block {
  width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;
  position: relative;
}

.show-area-1 .block:not(:last-child)::after {
  content: "";
  display: block;
  width: 144px;
  height: 22px;
  background-image: url("../../../assets/images/product/selfOrder/bg-3.png");
  background-size: contain;
  position: absolute;
  top: 43px;
  right: -72px;
  z-index: 1;
}

.show-area-1 .top {
  width: 94px;
  height: 108px;
  background-image: url("../../../assets/images/product/selfOrder/bg-2.png");
  background-size: contain;
  line-height: 108px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.show-area-1 .desc {
  font-size: 18px;
  color: #666666;
  line-height: 22px;
  text-align: center;
  margin-top: 34px;
}

.show-area-2 {
  margin-top: 75px;
  display: flex;
  height: 760px;
}

.show-area-2 .left {
  flex: none;
  width: 440px;
  height: 100%;
  background-color: #f1f2f8;
  border-radius: 16px 0 0 20px;
  background-image: url("../../../assets/images/product/selfOrder/bg-4.png");
  background-repeat: no-repeat;
  background-size: 356px 674px;
  background-position: center 80px;
}

.show-area-2 .right {
  padding-left: 60px;
  padding-right: 80px;
  padding-top: 120px;
}

.show-area-2 .title {
  font-size: 38px;
  font-weight: bold;
  color: #00a95f;
  line-height: 1;
}

.show-area-2 .subtitle {
  margin-top: 26px;
}

.show-area-2 .subtitle-block {
  width: 36px;
  height: 40px;
  padding: 2px 6px 6px;
  border: 1px solid #00aa5f;
  text-align: center;
  font-size: 18px;
  color: #00a95f;
  line-height: 22px;
  border-radius: 6px;
}

.show-area-2 .subtitle-price {
  font-size: 46px;
  font-weight: bold;
  color: #00a95f;
  line-height: 1;
  margin-left: 16px;
  vertical-align: text-bottom;
}

.show-area-2 .subtitle2 {
  font-size: 24px;
  color: #666666;
  margin-top: 22px;
}

.show-area-2 .other-1 {
  margin-top: 52px;
  padding-left: 47px;
  background-image: url("../../../assets/images/product/selfOrder/icon-4.png");
  background-size: 41px 41px;
  background-repeat: no-repeat;
  background-position: 0 2px;
}

.show-area-2 .other-1-inner {
  border-top: 1px solid #00a95f;
  padding-top: 22px;
  padding-bottom: 14px;
  font-size: 20px;
  font-weight: bold;
  color: #00a95f;
}

.show-area-2 .other-2 {
  padding-left: 36px;
  padding-bottom: 14px;
  border-bottom: 1px solid #00a95f;
}

.show-area-2 .other-2-line {
  font-size: 18px;
  color: #666666;
  line-height: 36px;
}

.show-area-2 .other-2-line::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #00a95f;
  vertical-align: middle;
  margin-right: 8px;
}

.show-area-2 .other-3 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 58px;
}

.show-area-2 .other-3-item {
  width: 122px;
  height: 32px;
  border: 1px solid #bcdcce;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 14px;
  font-size: 15px;
  color: #00a95f;
  line-height: 32px;
  text-align: center;
}

.section-2 {
  height: 1017px;
}

.section-2 .center-area {
  width: 1158px;
  height: 688px;
  background-image: url("../../../assets/images/product/selfOrder/bg-5.png");
  background-size: 100% 100%;
  padding-bottom: 40px;
}

.section-2 .section-title {
  transform: translateY(-8px);
}

.show-area-3 {
  margin-top: 56px;
  display: flex;
  justify-content: center;
}

.show-area-3 .card {
  width: 480px;
}

.show-area-3 .card-image {
  width: 100%;
  height: 340px;
  background-color: #f5f6fb;
  border-radius: 17px 17px 0 0;
}

.show-area-3 .card-image1 {
  background-image: url("../../../assets/images/product/selfOrder/bg-6.png");
  background-size: 393px 290px;
  background-repeat: no-repeat;
  background-position: 40px 20px;
}

.show-area-3 .card-image2 {
  background-image: url("../../../assets/images/product/selfOrder/bg-7.png");
  background-size: 408px 243px;
  background-repeat: no-repeat;
  background-position: 50px 60px;
}

.show-area-3 .card:first-child {
  margin-right: 30px;
}

.show-area-3 .part-1 {
  padding: 40px 0 30px 60px;
}

.show-area-3 .part-1-title {
  font-size: 24px;
  font-weight: bold;
  color: #00a95f;
}

.show-area-3 .part-1-content {
  margin-top: 22px;
  font-size: 18px;
  color: #666666;
  line-height: 24px;
}

.section-3 {
  height: 1180px;
  background-image: url("../../../assets/images/product/selfOrder/bg-8.png");
  background-size: cover;
  background-position: center;
}

.section-3 .center-area {
  padding-top: 87px;
}

.show-area-4 {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 73px;
}

.show-area-4 .card {
  width: 310px;
  border: none;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 550px;
}

.show-area-4 .card:not(:last-child) {
  margin-right: 35px;
}

.show-area-4 .card.card-image1 {
  background-image: url("../../../assets/images/product/selfOrder/bg-9.png");
}
.show-area-4 .card.card-image2 {
  background-image: url("../../../assets/images/product/selfOrder/bg-10.png");
}
.show-area-4 .card.card-image3 {
  background-image: url("../../../assets/images/product/selfOrder/bg-11.png");
}

.show-area-4 .card-content {
  padding: 40px 0 50px 37px;
}

.show-area-4 .card-content::before {
  content: "";
  display: block;
  width: 18px;
  height: 4px;
  background-color: #00a95f;
  margin-left: 3px;
}

.show-area-4 .card-title {
  padding-top: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #00a95f;
  line-height: 30px;
}

.show-area-4 .card-desc {
  font-size: 16px;
  color: #666666;
  line-height: 24px;
  margin-top: 25px;
}

.section-4 {
  height: 984px;
  padding-top: 197px;
}

.section-4 .center-area {
  width: 1087px;
  height: 688px;
  background-image: url("../../../assets/images/product/selfOrder/bg-13.png"),url("../../../assets/images/product/selfOrder/bg-18.png");
  background-size: 180px 121px, 100% 100%;
  background-position: 128px 135px, 0 0;
  background-repeat: no-repeat, no-repeat;
  position: relative;
}

.section-4 .image {
  position: absolute;
  width: 386px;
  height: 731px;
  background-image: url("../../../assets/images/product/selfOrder/bg-12.png");
  background-size: contain;
  top: 24px;
  right: 68px;
}

.section-4 .section-title {
  text-align: left;
  padding-left: 127px;
  background-position: 68px 50px;
}

.section-4 .section-subtitle {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: #00a95f;
  line-height: 30px;
  padding-left: 127px;
  margin-top: 259px;
}

.section-4 .section-text {
  padding-left: 127px;
  font-size: 18px;
  color: #666666;
  line-height: 30px;
  margin-top: 31px;
}

.section-5 {
  height: 1084px;
  background-image: url("../../../assets/images/product/selfOrder/bg-14.png");
  background-size: cover;
  background-position: center 0;
  padding-top: 98px;
  position: relative;
}

.section-5 .center-area {
  width: 844px;
  padding-top: 120px;
  position: relative;
}

.section-5 .image {
  position: absolute;
  width: 441px;
  height: 852px;
  background-image: url("../../../assets/images/product/selfOrder/bg-17.png");
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  left: -94px;
}

.show-area-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.show-area-5 .title {
  font-size: 80px;
  font-weight: bold;
  color: #00a95f;
  position: relative;
}

.show-area-5 .title::before {
  content: "";
  display: block;
  width: 146px;
  height: 22px;
  background-image: url("../../../assets/images/product/selfOrder/bg-16.png");
  background-size: contain;
  position: absolute;
  top: 30px;
  left: -161px;
}

.show-area-5 .subtitle {
  font-size: 60px;
  color: #ffffff;
  line-height: 66px;
  margin-top: 42px;
}

.show-area-5 .header-btn-contact {
  margin-top: 113px;
}

.show-area-5 .desc {
  width: 240px;
  font-size: 16px;
  color: #00a95f;
  margin-top: 16px;
  text-align: center;
}

.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000020;
}

.section-1 .btn-next-page {
  bottom: 120px;
}

.section-2 .btn-next-page {
  bottom: 180px;
}

.section-3 .btn-next-page {
  bottom: -150px;
}

.section-4 .btn-next-page {
  bottom: 150px;
}

.btn-back-top {
  width: 40px;
  height: 40px;
  background-image: url("../../../assets/images/product/selfOrder/x.png");
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 100px;
  right: calc(50% - 600px);
  cursor: pointer;
  display: none;
}

.btn-back-top.show {
  display: block;
}
</style>